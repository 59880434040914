import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'utils/link-resolver'
import getFluidSrc from 'utils/get-fluid-src'
import NormalizeVacancyData from 'utils/normalize-vacancy-data'
import AboutPageTpl from 'templates/about/page'

const AboutPage = ({ data }) => {
	const response = data.prismicSingleAbout.data
	const vacancies = data.allPrismicVacancy.nodes.map(NormalizeVacancyData)

	const content = {
		hero: {
			img_url: getFluidSrc(response.hero_image),
			ctaText: 'See how it all started',
			ctaTarget: '/about/#story',
			image: response.hero_image,
			video: response.hero_video,
		},
		innerLinks: [
			{ href: '/about/#team', text: 'Team' },
			{ href: '/about/#testimonials', text: 'Clients' },
			{ href: '/about/#careers', text: 'Careers' },
		],
		story: {
			topImage: response.story_image,
			gridImages: response.grid_images,
			boldParagraph: response.story_bold_paragraph,
			regularText: response.story_regular_text,
			secondBoldParagraph: response.story_second_bold_paragraph,
			secondRegularText: response.story_second_regular_text,
			secondImage: response.story_second_image,
		},
		values: {
			heading: response.values_heading.text,
			list: response.body.map((value) => value.primary),
			leftImage: response.values_left_image,
			rightImage: response.values_right_image,
		},
		team: {
			heading: response.team_heading.text,
			boldParagraph: response.team_bold_paragraph.text,
			members: response.team_members
				.filter(({ member }) => !member.isBroken)
				.map(({ member }) => member.document.data),
		},
		careers: {
			heading: response.careers_heading,
			vacancies,
		},
		aboutUs: {
			title: response.about_us_title.text,
			subtitle: response.about_us_subtitle.text,
			description: response.about_us_description,

			offers: response.body2[0],
		},
	}

	return <AboutPageTpl {...content} />
}

export const query = graphql`
	query singleAbout {
		prismicSingleAbout {
			_previewable
			id
			data {
				body {
					... on PrismicSingleAboutDataBodyTeamValue {
						primary {
							heading {
								text
							}
							column
							bold_paragraph
							regular_text
						}
					}
				}
				hero_cta
				hero_image {
					alt
					url
					dimensions {
						height
						width
					}
					fluid(maxWidth: 1440) {
						src
					}
				}
				hero_video {
					url
				}
				hero_subtitle
				hero_title {
					html
					richText
					text
				}
				story_image {
					alt
					url
					dimensions {
						height
						width
					}
				}
				story_bold_paragraph
				story_regular_text {
					richText
				}
				story_second_bold_paragraph
				story_second_image {
					alt
					url
					dimensions {
						height
						width
					}
				}
				story_second_regular_text {
					richText
				}
				grid_images {
					img {
						alt
						url
						fixed(width: 430) {
							width
							src
						}
					}
				}
				team_bold_paragraph {
					text
				}
				team_heading {
					text
				}
				values_heading {
					text
				}
				values_left_image {
					alt
					url
					dimensions {
						height
						width
					}
				}
				values_right_image {
					alt
					url
					dimensions {
						height
						width
					}
				}
				team_members {
					member {
						uid
						isBroken
						document {
							... on PrismicTeamMember {
								id
								data {
									name
									position
									shorter_text {
										richText
									}
									long_text {
										richText
									}
									square_funny_photo {
										url
										dimensions {
											height
											width
										}
									}
									square_serious_photo {
										url
										dimensions {
											height
											width
										}
									}
								}
							}
						}
					}
				}
				careers_heading

				about_us_description
				about_us_subtitle {
					text
					richText
				}
				about_us_title {
					text
					richText
				}
				body2 {
					... on PrismicSingleAboutDataBody2InternalLink {
						id
						items {
							offer_link {
								url
							}
							offer_name
						}
						primary {
							text
						}
					}
				}
			}
		}
		allPrismicVacancy(
			filter: { data: { is_active: { eq: true } } }
			sort: { order: ASC, fields: data___priority }
		) {
			nodes {
				uid
				data {
					location
					title
				}
			}
		}
	}
`

export default withPrismicPreview(AboutPage, [
	{
		repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
		linkResolver,
	},
])
