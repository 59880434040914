import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import {
	DesignRow,
	Heading2,
	Heading3,
	Heading4,
	IntenseText,
	RegularText,
} from 'components/design'
import { Rimg } from 'components/images'

/* Corresponding CSS at _about-team-member.css */
const TeamSection = ({ heading, boldParagraph, members }) => (
	<section id="team" className="mb-20 lg:mb-0">
		<DesignRow
			className="mt-32"
			leftColumn={<Heading2>{heading}</Heading2>}
			rightColumn={<IntenseText className="lg:mt-3">{boldParagraph}</IntenseText>}
		/>

		<div className="team-member-list flex flex-wrap lg:mt-20">
			{members.map((member, index) => (
				<div className="team-member" key={member.name}>
					<div className="team-member-photo-container group relative aspect-square">
						<Rimg
							className="absolute group-hover:invisible"
							image={member.square_serious_photo}
							position={6}
						/>
						<Rimg
							className="invisible absolute group-hover:visible"
							image={member.square_funny_photo}
							position={6}
						/>
					</div>

					<Heading3 className="mt-10">{member.name}</Heading3>
					<Heading4 className="text-zinc-400">{member.position}</Heading4>

					<RegularText className="mt-4">
						<PrismicRichText field={member.shorter_text.richText} />
					</RegularText>
				</div>
			))}
		</div>
	</section>
)

export default TeamSection
