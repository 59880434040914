import React from 'react'

export default function AboutUs({ title, subtitle, description, offers }) {
	return (
		<section id="about-us" className={'mt-18 grid grid-cols-16'}>
			<div className="about-us col-span-14 col-start-2 mx-auto lg:w-screen-step-17">
				<div className="mb-6">
					<h2 className="mb-8">{title}</h2>
					<h3 className="mb-7">{subtitle}</h3>
					<p className="text-zinc-600">{description}</p>
				</div>
				<div>
					<h3 className="mb-6">{offers.primary.text}</h3>
					<ul className="grid sm:grid-flow-col sm:grid-rows-5 sm:justify-start sm:gap-x-18">
						{offers.items.map((el) => (
							<li
								key={
									el.offer_link?.url
										? el.offer_link?.url
										: el.offer_name
								}
								className={'mb-0.5'}
							>
								{el.offer_link.url ? (
									<a
										href={el.offer_link.url}
										className={
											'accent-hover group inline-block h-3.5 cursor-pointer text-base font-semibold leading-none'
										}
									>
										<i className="mr-4 inline-block h-3 w-3 bg-black transition-colors duration-300 group-hover:bg-accent" />
										{el.offer_name}
									</a>
								) : (
									<p
										className={
											'group inline-block h-3.5 text-base font-semibold leading-none'
										}
									>
										<i className="mr-4 inline-block h-3 w-3 bg-black transition-colors duration-300" />
										{el.offer_name}
									</p>
								)}
							</li>
						))}
					</ul>
				</div>
			</div>
		</section>
	)
}
