import React from 'react'
import { Root } from 'templates/layout'
import InnerNav from './inner-nav'
import Team from './team'
import Testimonials from 'embeds/testimonials'
import Careers from './careers'
import DesignRow from 'components/design/DesignRow'
import { Heading2 } from 'components/design'
import AboutUs from './aboutUs'

const AboutPageTpl = ({ hero, innerLinks, story, values, team, careers, aboutUs }) => (
	<Root hero={hero}>
		<InnerNav innerLinks={innerLinks} contentWidth firstTitle={'About us'} />
		<AboutUs {...aboutUs} />
		<Team {...team} />
		<section id="testimonials">
			<DesignRow
				className="mt-5 md:mt-12 lg:mt-20 xl:mt-30  2xl:mt-40"
				leftColumn={<Heading2>Client testimonials</Heading2>}
			/>
			<Testimonials className={'mt-6 md:mt-10 lg:mt-14 xl:mt-20'} />
		</section>
		<Careers {...careers} />
	</Root>
)

export default AboutPageTpl

// Removed story & values
// <Story {...story} />
// <Values {...values} />
