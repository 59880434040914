import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const InnerNavSection = ({ innerLinks, contentWidth, firstTitle }) => (
	<section
		className={
			'inner-nav mx-6 mt-6 flex justify-between overflow-x-auto lg:mx-auto lg:justify-start ' +
			(contentWidth ? 'lg:w-screen-step-18' : 'lg:w-screen-step-26')
		}
	>
		<div className="inner-link-current py-4 lg:mr-32">{firstTitle}</div>
		{innerLinks.map((link) => (
			<AnchorLink
				to={link.href}
				key={link.href}
				className="inner-link ml-8 py-4 lg:ml-0 lg:mr-32"
			>
				{link.text}
			</AnchorLink>
		))}
	</section>
)

export default InnerNavSection
