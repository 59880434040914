import React from 'react'
import VacanciesList from 'templates/careers/list'
import { DesignRow, Heading2 } from 'components/design'

const CareersSection = ({ heading, vacancies }) => (
	<section id="careers" className={vacancies.length ? '' : 'hidden'}>
		<DesignRow
			className="mt-20 lg:mt-40"
			leftColumn={<Heading2>{heading}</Heading2>}
		/>
		<VacanciesList vacancies={vacancies} />
	</section>
)

export default CareersSection
